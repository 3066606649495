import React, { useState } from "react";
import {
	Activity,
	User,
	ActivityScheme,
	UserScheme,
	ResidenceScheme,
	CoorporateScheme,
	ActivityRenderScheme,
	ErrorScheme,
} from "client-v2";

import { Modal, TextArea } from "@adoptaunabuelo/react-components";

const NoteModal = (props: Props) => {
	const [note, setNote] = useState("");
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);

	const handleForm = (value: string) => {
		setNote(value);
	};

	const onSave = async () => {
		setLoading(true);
		const currentUser = await User.currentAsync();
		if (props.type === "edit") {
			Activity.set(props.data.objectId, {
				data: {
					...props.data.data,
					note: note,
					pinned: props.data.pinned,
				},
			})
				.then((result: ActivityScheme) => {
					setLoading(false);
					props.onSave(result);
				})
				.catch((error: ErrorScheme) => {
					setLoading(false);
					setError(error.message);
				});
		} else {
			Activity.create({
				fromId: props.user ? props.user.objectId : undefined,
				adminId: currentUser.data.objectId,
				type: "note",
				data: {
					note: note,
					Coorporate: props.coorporate
						? props.coorporate.objectId
						: undefined,
					Residence: props.residence
						? props.residence.objectId
						: undefined,
				},
			})
				.then((result) => {
					setLoading(false);
					props.onSave(result.data);
				})
				.catch((error: ErrorScheme) => {
					setLoading(false);
					setError(error.message);
				});
		}
	};

	return (
		<Modal
			isVisible={props.isVisible}
			style={{ width: 569 }}
			title={props.type === "edit" ? "Editar nota" : "Añadir nota"}
			onClose={props.onClose}
			error={error}
			buttonProps={{
				onClick: onSave,
				children: "Guardar",
				loading: loading,
			}}
		>
			<TextArea
				style={{ height: 300 }}
				type={"edit"}
				onChange={handleForm}
				value={props.data ? props.data.subtitle : ""}
			/>
		</Modal>
	);
};
export default NoteModal;
export interface Props {
	isVisible: boolean;
	data: ActivityRenderScheme & { data: any };
	type: "edit" | "new";
	user?: UserScheme;
	residence?: ResidenceScheme;
	coorporate?: CoorporateScheme;
	onSave: (activity: ActivityScheme) => void;
	onClose: () => void;
}
