import { Input, Modal } from "@adoptaunabuelo/react-components";
import { Activity, UserScheme, ErrorScheme } from "client-v2";
import moment from "moment";
import React, { ChangeEvent, useState } from "react";
import styled from "styled-components";

const Column = styled.div`
	display: flex;
	flex-direction: column;
	padding: 4px 0px 12px;
	gap: 8px;
`;
const Row = styled.div`
	display: flex;
	flex-direction: row;
	gap: 8px;
`;

const AddVisitModal = (props: AddVisitModalProps) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string | undefined>(undefined);
	const [selectedDate, setSelectedDate] = useState<Date | undefined>(
		undefined
	);
	
	const [startDate, setStartDate] = useState<moment.Moment | undefined>(
		undefined
	);
	const [endDate, setEndDate] = useState<moment.Moment | undefined>(
		undefined
	);

	const onHourChange = (type: "start" | "end", e: any) => {
		const tempTime = moment(e.target.value, "HH:mm");
		if (type === "start") {
			setStartDate(tempTime);
		} else if (type === "end") {
			setEndDate(tempTime);
		}
	};

	const onSave = () => {
		setError(undefined);

		if (!selectedDate) {
			setError("Selecciona una fecha para la visita.");
			return;
		}

		if (startDate && endDate) {
			setLoading(true);
			const duration = moment
				.duration(endDate.diff(startDate))
				.asMinutes();
			if (duration > 0) {
				const adjustToGMT = (date: moment.Moment, offset: number) => {
					return date
						.utcOffset(offset)
						.format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
				};

				const start = adjustToGMT(
					moment(selectedDate).set({
						hour: startDate.hours(),
						minute: startDate.minutes(),
						second: 0,
						millisecond: 0,
					}),
					60
				);

				const end = adjustToGMT(
					moment(selectedDate).set({
						hour: endDate.hours(),
						minute: endDate.minutes(),
						second: 0,
						millisecond: 0,
					}),
					60
				);

				Activity.create({
					type: "visit",
					fromId: props.volunteer.objectId,
					toId: props.grandpa.objectId,
					data: {
						start: start,
						end: end,
						duration: duration,
					},
				})
					.then(() => {
						setLoading(false);
						props.onSave();
						props.onClose();
						setStartDate(undefined);
					})
					.catch((e: ErrorScheme) => {
						setLoading(false);
						setError(e.message);
					});
			} else {
				setLoading(false);
				setError("La hora de inicio debe ser menor a la hora de fin");
			}
		} else {
			setError("Selecciona una de inicio y de fin de la visita");
		}
	};

	const handleDateChange = (event: ChangeEvent<HTMLInputElement> | Date) => {
		let dateValue: string | Date;

		if (event instanceof Date) {
			dateValue = event;
		} else if ("target" in event && event.target.value) {
			dateValue = event.target.value;
		} else {
			console.warn(
				"Advertencia: No se pudo obtener el valor de la fecha."
			);
			return;
		}

		const isValidDate = (dateStr: string) => {
			const regex = /^([0-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{4}$/;
			if (!regex.test(dateStr)) return false;

			const [day, month, year] = dateStr.split("/").map(Number);
			return moment(
				`${year}-${month}-${day}`,
				"YYYY-MM-DD",
				true
			).isValid();
		};

		let momentDate;
		if (typeof dateValue === "string") {
			if (!isValidDate(dateValue)) {
				setError("La fecha debe tener un formato válido (dd/mm/aaaa).");
				return;
			}

			const [day, month, year] = dateValue.split("/").map(Number);
			momentDate = moment(`${year}-${month}-${day}`, "YYYY-MM-DD");
		} else {
			momentDate = moment(dateValue, moment.ISO_8601, true).isValid()
				? moment(dateValue)
				: moment(dateValue, "YYYY-MM-DD");
		}

		if (momentDate.isValid()) {
			setSelectedDate(momentDate.toDate());
			setError(undefined);
		} else {
			setError("La fecha ingresada no es válida.");
		}
	};

	const handleCloseModal = () => {
		setStartDate(undefined);
		setEndDate(undefined);
		setSelectedDate(undefined);
		setError(undefined);
		props.onClose();
	};

	return (
		<Modal
			isVisible={props.isVisible}
			shouldCloseOnOverlayClick={true}
			title="Registrar visita"
			buttonProps={{
				children: "Guardar",
				loading: loading,
				onClick: onSave,
			}}
			error={error}
			onClose={handleCloseModal}
		>
			<Column>
				<Row>
					<Input
						type="date"
						design={"secondary"}
						placeholder="DD/MM/AAAA"
						hideCalendar={true}
						onChange={handleDateChange}
					/>
					<Input
						containerStyle={{ display: "flex", flex: 1 }}
						type={"time"}
						design={"secondary"}
						placeholder="Hora de inicio"
						onChange={(e: any) => onHourChange("start", e)}
					/>
					<Input
						containerStyle={{ display: "flex", flex: 1 }}
						type={"time"}
						design={"secondary"}
						placeholder="Hora de fin"
						onChange={(e: any) => onHourChange("end", e)}
					/>
				</Row>
			</Column>
		</Modal>
	);
};
export default AddVisitModal;
export interface AddVisitModalProps {
	isVisible: boolean;
	volunteer: UserScheme;
	grandpa: UserScheme;
	onSave: () => void;
	onClose: () => void;
}
