import React, { useState } from "react";
import styled from "styled-components";
import {
	Subscription,
	UserScheme,
	SubscriptionScheme,
	ProductScheme,
	PaymentMethodScheme,
	ErrorScheme,
} from "client-v2";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreators, State } from "../../redux";
import { bindActionCreators } from "redux";

import PauseModal from "../../components/Modals/PauseModal";
import SubscriptionModal from "../../components/Modals/SubscriptionModal";
import {
	Pause,
	Play,
	Trash2,
	Plus,
	MoreHorizontal,
	Pen,
	X,
	Clock,
	Coins,
} from "lucide-react";
import {
	Text,
	Button,
	Color,
	Menu,
	Label,
} from "@adoptaunabuelo/react-components";
import CancelationModal from "../../components/Modals/CancelationModal";

const Container = styled.div`
	position: relative;
`;
const TitleHeader = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 13px;
	align-items: center;
`;
const Cell = styled.div`
	display: flex;
	gap: 8px;
`;
const Col = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;
const Row = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
`;

const SectionSubscription = (props: Props) => {
	const dispatch = useDispatch();
	const { setProfileFunction } = bindActionCreators(
		actionsCreators,
		dispatch
	);
	const currentUser = useSelector((state: State) => state.currentUser);

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const [showActiveModal, setShowActiveModal] = useState(false);
	const [showCancelModal, setShowCancelModal] = useState(false);
	const [showPauseModal, setShowPauseModal] = useState(false);
	const [cancelationStep, setCancelationStep] = useState(0);
	const [selectedOption, setSelectedOption] = useState(undefined);

	const onOptionClick = (option: any) => {
		setSelectedOption(option.id);
		if (option.id === "pause" || option.id === "reactive") {
			setShowPauseModal(true);
		} else if (option.id === "cancel") {
			setShowCancelModal(true);
		} else if (option.id === "edit") {
			setShowActiveModal(true);
		} else if (option.id === "one-off-payment") {
			setShowActiveModal(true);
		}
	};

	const cancelSubscription = async (motivation: any) => {
		setLoading(true);
		Subscription.remove({
			userId: props.user.objectId,
			motivation: motivation ? motivation.id : "other",
		})
			.then(async (subscription) => {
				await setProfileFunction({ launch: true, function: "subscription"});
				await setProfileFunction({ launch: true, function: "activity" });
				setShowCancelModal(false);
				setLoading(false);
			})
			.catch((error: ErrorScheme) => {
				setError(error.message);
				setLoading(false);
			});
	};

	return (
		<>
			<PauseModal
				isVisible={showPauseModal}
				user={props.user}
				onClose={() => setShowPauseModal(false)}
				subscription={props.subscription}
				onSave={async () => {
					await setProfileFunction({ launch: true, function: "subscription" });
					await setProfileFunction({ launch: true, function: "activity" });
					setShowPauseModal(false);
				}}
			/>
			<SubscriptionModal
				isVisible={showActiveModal}
				user={props.user}
				currentUser={currentUser}
				paymentMethods={props.paymentMethods}
				subscription={
					props.subscription &&
					props.subscription.state !== "cancel" &&
					selectedOption !== "one-off-payment"
						? props.subscription
						: undefined
				}
				product={
					props.subscription &&
					props.subscription.state !== "cancel" &&
					selectedOption !== "one-off-payment"
						? props.product
						: undefined
				}
				selectedTab={
					selectedOption === "one-off-payment" ? "one" : undefined
				}
				onClose={() => setShowActiveModal(false)}
				onSave={async () => {
					await setProfileFunction({
						launch: true,
						function: "subscription",
					});
					await setProfileFunction({ launch: true, function: "activity" });
					setShowActiveModal(false);
				}}
			/>
			<CancelationModal
				isVisible={showCancelModal}
				title={
					cancelationStep === 0
						? "¿Cuál es el motivo de la baja?"
						: "¿Seguro que quieres cancelar esta aportación?"
				}
				error={error}
				loading={loading}
				options={[
					{
						id: "economic",
						label: "Quiero recortar gastos",
					},
					{
						id: "other-asociation",
						label: "Prefiere ayudar a otra asociación",
					},
					{
						id: "aua",
						label: "Mi experiencia no ha sido buena",
					},
				]}
				onStepChange={(step: number) => setCancelationStep(step)}
				onSave={cancelSubscription}
				onClose={() => setShowCancelModal(false)}
			/>
			<Container>
				<TitleHeader>
					<Row style={{ alignItems: "center", gap: 8 }}>
						<Text type="p" weight={"semibold"}>
							Donación
						</Text>
						{props.subscription && (
							<Label
								style={{
									fontSize: 10,
									padding: "0px 4px",
									height: 22,
									backgroundColor: "#EBF9FF",
								}}
								color={"#2D55B5"}
								size="small"
								type="label"
								text={
									props.subscription?.stripeId
										? "Stripe"
										: "Domiciliación"
								}
							/>
						)}
					</Row>
					{props.subscription &&
					props.subscription.state !== "cancel" ? (
						<Menu
							id={"subscription-menu"}
							style={{ position: "absolute", right: 0, top: -10 }}
							menuStyle={{ width: 200 }}
							icon={<MoreHorizontal height={20} width={20} />}
							options={
								props.subscription &&
								props.subscription.state === "paused"
									? [
											{
												id: "edit",
												label: "Editar",
												icon: (
													<Pen
														height={20}
														width={20}
													/>
												),
											},
											{
												id: "reactive",
												label: "Reactivar",
												icon: (
													<Play
														height={20}
														width={20}
													/>
												),
											},
											{
												id: "cancel",
												label: "Cancelar",
												icon: (
													<Trash2
														height={20}
														width={20}
													/>
												),
											},
											{
												id: "one-off-payment",
												label: "Pago puntual",
												icon: (
													<Coins
														height={20}
														width={20}
													/>
												),
											},
									  ]
									: [
											{
												id: "edit",
												label: "Editar",
												icon: (
													<Pen
														height={20}
														width={20}
													/>
												),
											},
											{
												id: "pause",
												label: "Pausar",
												icon: (
													<Pause
														height={20}
														width={20}
													/>
												),
											},
											{
												id: "cancel",
												label: "Cancelar",
												icon: (
													<Trash2
														height={20}
														width={20}
													/>
												),
											},
											{
												id: "one-off-payment",
												label: "Pago puntual",
												icon: (
													<Coins
														height={20}
														width={20}
													/>
												),
											},
									  ]
							}
							position={"bottom-left"}
							onClick={onOptionClick}
						/>
					) : (
						<Button
							onClick={() => setShowActiveModal(true)}
							design={"image"}
							icon={
								<Plus
									height={20}
									width={20}
									color={Color.text.primary}
								/>
							}
							style={{ position: "absolute", right: 0, top: -10 }}
						/>
					)}
				</TitleHeader>
				{props.subscription && props.product ? (
					<Cell>
						{props.subscription.state === "paused" ? (
							<Pause
								style={{ marginTop: 4 }}
								height={20}
								width={20}
								color={Color.status.color.warning}
							/>
						) : props.subscription.state === "cancel" ? (
							<X
								style={{ marginTop: 4 }}
								height={20}
								width={20}
								color={Color.status.color.error}
							/>
						) : props.subscription.state === "trialing" ? (
							<Clock
								style={{ marginTop: 4 }}
								height={20}
								width={20}
								color={Color.text.high}
							/>
						) : null}
						<Col>
							<Text
								type="p"
								style={{
									color:
										props.subscription.state !== "active"
											? Color.text.high
											: Color.text.primary,
									fontSize: 18,
								}}
							>
								{props.product.price}
								{props.product.charge === "monthly"
									? "€/mes"
									: props.product.charge === "quaterly"
									? "€/trimestral"
									: "€/año"}
							</Text>
							{props.subscription.state === "cancel" ? (
								<Text
									type="p"
									style={{
										fontSize: 12,
										color: Color.text.high,
										marginTop: 2,
									}}
								>
									Cancelada el día{" "}
									{moment(
										props.subscription.endDate.iso
									).format("DD/MM/YYYY")}
								</Text>
							) : props.subscription.state === "trialing" ? (
								<Text
									type="p"
									style={{
										fontSize: 12,
										color: Color.text.high,
										marginTop: 2,
									}}
								>
									Se activará el día{" "}
									{moment(
										props.subscription.endDate.iso
									).format("DD/MM/YYYY")}
								</Text>
							) : (
								<Col>
									<Text
										type="p"
										style={{
											fontSize: 12,
											color: Color.text.high,
											marginTop: 2,
										}}
									>
										Desde{" "}
										{moment(
											props.subscription.createdAt
										).format("DD/MM/YYYY")}
									</Text>
									{props.subscription.endDate &&
										(props.subscription.state ===
										"paused" ? (
											<Text
												type="p"
												style={{
													fontSize: 12,
													color: Color.text.high,
													marginTop: 2,
												}}
											>
												Fecha de reactivación{" "}
												{moment(
													props.subscription.endDate
														.iso
												).format("DD/MM/YYYY")}
											</Text>
										) : (
											<Text
												type="p"
												style={{
													fontSize: 12,
													color: Color.text.high,
													marginTop: 2,
												}}
											>
												Fecha renovación{" "}
												{moment(
													props.subscription.endDate
														.iso
												).format("DD/MM/YYYY")}
											</Text>
										))}
								</Col>
							)}
							{props.subscription.motivation && (
								<Text
									type="p"
									style={{
										fontSize: 12,
										color: Color.text.high,
										marginTop: 2,
									}}
								>
									Motivo {props.subscription.motivation}
								</Text>
							)}
						</Col>
					</Cell>
				) : (
					<Text type="p2" style={{ color: Color.text.medium }}>
						No hay ninguna donación activa
					</Text>
				)}
			</Container>
		</>
	);
};
export default SectionSubscription;
export interface Props {
	user: UserScheme;
	subscription?: SubscriptionScheme;
	product?: ProductScheme;
	paymentMethods?: Array<PaymentMethodScheme>;
}
