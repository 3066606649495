import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import {
	Activity,
	Corporate,
	ActivityScheme,
	CoorporateScheme,
	ErrorScheme,
	Dash,
	ActivityRenderScheme,
} from "client-v2";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreators, State } from "../../../redux";
import moment from "moment";

import AdminBar from "../../../components/Nav/AdminBar";
import Row from "../../../components/Containers/Row";
import Col from "../../../components/Containers/Col";
import Spinner from "../../../components/Spinner/Spinner";
import SectionMain from "./SectionMain";
import SectionReporting from "./SectionReporting";
import SectionNotes from "./SectionNotes";
import SectionVolunteer from "./SectionVolunteer";
import SectionAnalytics from "./SectionAnalytics";

const Container = styled.div`
	display: flex;
	width: -webkit-fill-available;
	flex-direction: column;
	padding: 0px 32px 32px;
	height: 100vh;
	overflow: scroll;
`;

const Profile = (props: Props) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { setLoading, setError, setCoorporateProfileFunction } =
		bindActionCreators(actionsCreators, dispatch);
	const coorporateProfileFunction = useSelector(
		(state: State) => state.profile
	);
	const loading = useSelector((state: State) => state.modal.loading);

	const [coorporate, setCoorporate] = useState<CoorporateScheme | undefined>(
		undefined
	);
	const [notes, setNotes] = useState<Array<ActivityRenderScheme>>([]);
	const [nVolunteers, setNVolunteers] = useState(0);
	const [volunteers, setVolunteers] = useState<
		{
			name: string;
			surname: string;
			id: string;
			incidence: boolean;
			state: string;
			image?: string;
			callDate: Date;
		}[]
	>([]);
	const [analytics, setAnalytics] = useState<any>(undefined);

	useEffect(() => {
		setCoorporateProfileFunction({ launch: true, function: "all" });
	}, []);

	useEffect(() => {
		const coorporateId = new URLSearchParams(window.location.search).get(
			"id"
		);
		if (coorporateProfileFunction.launch && coorporateId) {
			get(coorporateId);
		}
	}, [coorporateProfileFunction]);

	const get = (coorporateId: string) => {
		if (coorporateProfileFunction.function === "all") {
			setLoading(true);
			Dash.getCorporate(coorporateId)
				.then((result) => {
					setCoorporate(result.data.Corporate);
					setNotes(result.data.Notes);
					setVolunteers(result.data.Volunteers.data);
					setNVolunteers(result.data.Volunteers.pagination.count);
					setAnalytics(result.data.Analytics);
					setLoading(false);
				})
				.catch((e: ErrorScheme) => {
					setLoading(false);
					setError(true, e.message);
				});
		} else if (coorporate) {
			if (coorporateProfileFunction.function === "notes") {
				getNotes(coorporate)
					.then(async (result) => {
						const notes = generateScheme(result.data, "notes");
						setNotes(notes);
					})
					.catch((error: ErrorScheme) => {
						setError(true, error.message);
					});
			} else if (coorporateProfileFunction.function === "volunteer") {
				getVolunteers(coorporate, coorporateProfileFunction.data).then(
					(result) => {
						setNVolunteers(result.pagination.count);
						setVolunteers(result.data);
					}
				);
			}
		}
		setCoorporateProfileFunction({ launch: false, function: "all" });
	};

	const getNotes = async (coorporate: CoorporateScheme) => {
		return Activity.get({
			type: "note",
			data: {
				coorporateId: coorporate.objectId,
			},
			descending: "createdAt",
			include: ["Admin"],
		});
	};

	const generateScheme = (
		items: ActivityScheme[],
		type: string
	): ActivityRenderScheme[] => {
		return items.map((item) => {
			return {
				objectId: item.objectId,
				type: item.type,
				From: item.From,
				timestamp: moment(item.createdAt).toDate(),
				title: "Nota",
				subtitle: item.data && item.data.note ? item.data.note : "",
				pinned: item.data?.pinned,
				Admin: item.Admin
					? {
							name: item.Admin.name,
							image: item.Admin.image?.url,
					  }
					: undefined,
			};
		});
	};

	const getVolunteers = async (
		coorporate: CoorporateScheme,
		filter?: { state: string }
	) => {
		return Corporate.getVolunteers(coorporate.objectId, {
			state: filter ? (filter.state as any) : "incidence",
		});
	};

	const onCoorporateChange = (coorporate: CoorporateScheme) => {
		setCoorporate(coorporate);
	};

	return loading ? (
		<Spinner />
	) : (
		<Container>
			{coorporate && (
				<>
					<AdminBar
						style={{ marginBottom: 16 }}
						title="Detalle"
						goBack={() => navigate(-1)}
					/>
					<Row style={{ width: "100%" }}>
						<Col style={{ width: "33%" }}>
							<SectionMain
								coorporate={coorporate}
								onCoorporateChanged={onCoorporateChange}
							/>
						</Col>
						<Col
							style={{
								width: "33%",
								marginLeft: 16,
								marginRight: 16,
							}}
						>
							<SectionAnalytics
								coorporate={coorporate}
								analytics={analytics}
								onCoorporateChanged={onCoorporateChange}
							/>
							<SectionVolunteer
								coorporate={coorporate}
								userArray={volunteers}
								userCount={nVolunteers}
								onCoorporateChanged={onCoorporateChange}
							/>
						</Col>
						<Col style={{ width: "33%" }}>
							<SectionReporting
								coorporate={coorporate}
								onCoorporateChanged={onCoorporateChange}
							/>
							<SectionNotes
								coorporate={coorporate}
								notes={notes}
								onCoorporateChanged={onCoorporateChange}
							/>
						</Col>
					</Row>
				</>
			)}
		</Container>
	);
};
export default Profile;
export interface Props {}
