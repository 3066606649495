import React, { ComponentPropsWithoutRef, useState } from "react";
import styled from "styled-components";
import parse from "html-react-parser";
import moment from "moment";

import { Edit2, Trash2, MoreHorizontal, Pin } from "lucide-react";
import {
	Text,
	Color,
	Menu,
	Avatar,
	ColorV2,
} from "@adoptaunabuelo/react-components";
import { ActivityRenderScheme } from "client-v2";
import Row from "../Containers/Row";

const Container = styled.div<{ pinned?: boolean }>`
	display: flex;
	position: relative;
	flex-direction: column;
	width: 100%;
	box-sizing: border-box;
	border-bottom: 1px solid ${Color.line.soft};
	padding: 12px 0px;
`;
const AdminDiv = styled.div`
	display: flex;
	flex-direction: row;
`;
const Column = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`;
const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const TxtContainer = styled.div`
	font-family: "Poppins";
	font-size: 14px;
	color: ${Color.text.full};
`;

const Note = (props: Props) => {
	const onOptionClick = (option: any) => {
		if (option.id === "edit") {
			props.openModal(props.note);
		} else if (option.id === "remove") {
			props.onRemove();
		} else if (option.id === "stick") {
			props.pinComment();
		}
	};

	return (
		<Container pinned={props.pinned} style={props.style}>
			{props.note.Admin && (
				<Header>
					<AdminDiv>
						<Column>
							{props.pinned && (
								<Row>
									<Pin
										height={16}
										width={16}
										color={ColorV2.text.neutralMedium}
										fill={ColorV2.text.neutralMedium}
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											marginLeft: 24,
										}}
									/>
									<Text
										type="c1"
										weight="medium"
										style={{
											marginLeft: 8,
											color: ColorV2.text.neutralMedium,
										}}
									>
										Fijado
									</Text>
								</Row>
							)}
							<Row>
								<Avatar
									style={{
										height: 36,
										width: 36,
										marginRight: 12,
									}}
									name={props.note.Admin.name}
									icon={props.note.Admin.image}
								/>
								<Column>
									<Text type="p2" weight="medium">
										{props.note.Admin.name}
									</Text>
									{props.note.timestamp && (
										<Text
											type="c1"
											style={{ color: Color.text.high }}
										>
											{moment(
												props.note.timestamp
											).format("DD/MM/YYYY")}
										</Text>
									)}
								</Column>
							</Row>
						</Column>
					</AdminDiv>
				</Header>
			)}

			<Menu
				id={"note-menu-" + props.note.timestamp}
				options={[
					{
						id: "stick",
						label: props.pinned ? "Desfijar" : "Fijar",
						icon: <Pin height={20} width={20} />,
					},
					{
						id: "edit",
						label: "Editar",
						icon: <Edit2 height={20} width={20} />,
					},
					{
						id: "remove",
						label: "Eliminar",
						icon: <Trash2 height={20} width={20} />,
					},
				]}
				icon={
					<MoreHorizontal
						height={20}
						width={20}
						color={Color.text.full}
					/>
				}
				menuStyle={{ width: "unset" }}
				position={"bottom-left"}
				onClick={onOptionClick}
				style={{ position: "absolute", top: 12, right: 0 }}
			/>
			<TxtContainer>{parse(props.note.subtitle)}</TxtContainer>
		</Container>
	);
};

export default Note;

export interface Props extends ComponentPropsWithoutRef<"div"> {
	note: ActivityRenderScheme;
	openModal: (note: ActivityRenderScheme) => void;
	pinned?: boolean;
	onRemove: () => void;
	pinComment: () => void;
}
