import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { actionsCreators } from "../../../redux";
import { bindActionCreators } from "redux";
import {
	Activity,
	ActivityRenderScheme,
	ErrorScheme,
	UserScheme,
} from "client-v2";

import Card from "../../../components/Containers/Card";
import { Text, Button, Color, Modal, ColorV2 } from "@adoptaunabuelo/react-components";
import { Plus } from "lucide-react";
import Note from "../../../components/Profile/Note";
import NoteModal from "../../../components/Modals/NoteModal";

const TitleHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 13px;
`;

const SectionNotes = (props: Props) => {
	const dispatch = useDispatch();
	const { setProfileFunction, setError } = bindActionCreators(
		actionsCreators,
		dispatch
	);

	const [modalNote, setModalNote] = useState<{
		show: boolean;
		type: "edit" | "new";
		data?: any;
	}>({
		show: false,
		type: "new",
		data: undefined,
	});
	const [showRemoveModal, setShowRemoveModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [selectedNote, setSelectedNote] = useState<
		ActivityRenderScheme | undefined
	>(undefined);

	const [selectedNotePinned, setSelectedNotePinned] =
		useState<boolean>(false);
	const [showPinModal, setShowPinModal] = useState(false);

	const onNoteUpdated = () => {
		setProfileFunction({ launch: true, function: "notes" });
		setModalNote({ ...modalNote, show: false });
	};

	const onRemoveClick = (note: ActivityRenderScheme) => {
		setShowRemoveModal(true);
		setSelectedNote(note);
	};

	const removeNote = async () => {
		if (selectedNote) {
			setLoading(true);
			Activity.remove(selectedNote.objectId)
				.then((result) => {
					setLoading(false);
					setShowRemoveModal(false);
					setProfileFunction({ launch: true, function: "notes" });
				})
				.catch((error: ErrorScheme) => {
					setLoading(false);
					setShowRemoveModal(false);
					setError(true, error.message);
				});
		}
	};

	const togglePinModal = (note: any) => {
		setSelectedNote(note);
		setSelectedNotePinned(
			note.pinned && !isNaN(new Date(note.pinned).getTime())
		);
		setShowPinModal(true);
	};

	const togglePinNote = async () => {
		if (!selectedNote) return;

		setLoading(true);

		const noteData: any = {
			note: selectedNote.subtitle,
		};

		if (!selectedNotePinned) {
			noteData.pinned = new Date();
		}

		Activity.set(selectedNote.objectId, { data: noteData })
			.then(() => {
				setLoading(false);
				setShowPinModal(false);
				setProfileFunction({
					launch: true,
					function: "notes",
				});
			})
			.catch(() => {
				setLoading(false);
			});
	};

	const sortNotes = (notes : any) => {
		return [...notes].sort((a, b) => {
			const isPinnedA = a.pinned && !isNaN(new Date(a.pinned).getTime());
			const isPinnedB = b.pinned && !isNaN(new Date(b.pinned).getTime());
	
			if (isPinnedA && !isPinnedB) return -1;
			if (!isPinnedA && isPinnedB) return 1;
	
			return (
				(b.pinned ? new Date(b.pinned).getTime() : 0) -
				(a.pinned ? new Date(a.pinned).getTime() : 0)
			);
		});
	};

	const isNotePinned = (note : any) => {
		return note.pinned && !isNaN(new Date(note.pinned).getTime());
	};
	
	const shouldShowSeparator = (index: number, sortedNotes: any[], addedSeparator: boolean) => {
		return (
			!isNotePinned(sortedNotes[index]) &&
			index > 0 &&
			!addedSeparator &&
			isNotePinned(sortedNotes[index - 1])
		);
	};

	return (
		<>
			<NoteModal
				isVisible={modalNote.show}
				user={props.user}
				type={modalNote.type}
				data={modalNote.data}
				onSave={onNoteUpdated}
				onClose={() => setModalNote({ ...modalNote, show: false })}
			/>
			<Modal
				isVisible={showPinModal}
				shouldCloseOnOverlayClick={true}
				title={
					selectedNotePinned
						? "¿Quieres desfijar este comentario?"
						: "¿Quieres fijar este comentario?"
				}
				onClose={() => setShowPinModal(false)}
				buttonProps={{
					children: selectedNotePinned
						? "Desfijar nota"
						: "Fijar nota",
					onClick: togglePinNote,
					loading: loading,
				}}
			/>
			<Modal
				isVisible={showRemoveModal}
				title={"¿Seguro que quieres continuar?"}
				subtitle={"Al eliminar este comentario no se podrá recuperar"}
				onClose={() => setShowRemoveModal(false)}
				buttonProps={{
					children: "Eliminar comentario",
					onClick: removeNote,
					loading: loading,
				}}
			/>
			<Card style={{ position: "relative" }}>
				<TitleHeader>
					<Text type="p" weight={"semibold"}>
						Notas
					</Text>
					<Button
						design={"image"}
						icon={
							<Plus
								height={20}
								width={20}
								color={Color.text.primary}
							/>
						}
						onClick={() => {
							setModalNote({ type: "new", show: true });
						}}
						style={{ position: "absolute", right: 16, top: 12 }}
					/>
				</TitleHeader>
				{props.notesArray.length > 0 ? (
					(() => {
						const sortedNotes = sortNotes(props.notesArray);

						let addedSeparator = false;

						return sortedNotes.map((note, index) => {
							
							const isPinned = isNotePinned(note);
							const showSeparator = shouldShowSeparator(index, sortedNotes, addedSeparator);
							if (showSeparator) addedSeparator = true;

							return (
								<React.Fragment key={"note_" + note.objectId}>
									{showSeparator && <hr style={{ border: `1.5px solid ${ColorV2.border.neutralMedium}`, margin: "0px 0"}} />}
									<Note
										openModal={(note) =>
											setModalNote({
												show: true,
												data: note,
												type: "edit",
											})
										}
										pinned={isPinned}
										note={note}
										onRemove={() => onRemoveClick(note)}
										pinComment={() => togglePinModal(note)}
									/>
								</React.Fragment>
							);
						});
					})()
				) : (
					<Text type="p2" style={{ color: Color.text.high }}>
						No hay notas de momento
					</Text>
				)}
			</Card>
		</>
	);
};

export default SectionNotes;
export interface Props {
	user: UserScheme;
	notesArray: Array<ActivityRenderScheme>;
}
